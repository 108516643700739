import React from 'react'
import Matches from '../management/Matches'

function Calendary() {
  return (
	<div className='main-margin'>
		<Matches />
	</div>
  )
}

export default Calendary
